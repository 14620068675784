import { STOP_TIMER, UPDATE_TIME } from "../constants";

const initialState = {
  time: { hours: 0, minutes: 1, seconds: 0 },
  isActive: true,
};

const timerReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_TIME:
      return {
        ...state,
        time: action.payload,
      };
    case STOP_TIMER:
      return {
        ...state,
        isActive: false,
      };
    default:
      return state;
  }
};

export default timerReducer;
