import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";


const AssessmentTimer = ({ timeInMinutes, handleSubmit }) => {

  const initialTime = {
    hours: Math.floor(timeInMinutes / 60),
    minutes: timeInMinutes % 60,
    seconds: 0,
  };

  const getStoredTime = () => {
    const storedTime = localStorage.getItem("assessment-timer");
    return storedTime ? JSON.parse(storedTime) : initialTime;
  };

  const [time, setTime] = useState(getStoredTime);
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    let interval;

    if (isActive) {
      interval = setInterval(() => {
        let { hours, minutes, seconds } = time;

        // Check if the timer has reached 00:00:00
        if (hours === 0 && minutes === 0 && seconds === 0) {
          clearInterval(interval);
          setIsActive(false);
          handleSubmit(); // Submit the assessment when time runs out
          localStorage.removeItem("assessment-timer"); // Clear the timer
          return;
        }

        // Decrement the seconds
        seconds--;

        // Handle seconds going below 0
        if (seconds < 0) {
          seconds = 59;
          minutes--;
        }

        // Handle minutes going below 0
        if (minutes < 0) {
          minutes = 59;
          hours--;
        }

        const updatedTime = { hours, minutes, seconds };

        // Update local time state and store it in localStorage
        setTime(updatedTime);
        localStorage.setItem("assessment-timer", JSON.stringify(updatedTime));
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [isActive, time]);


  return (
    <div className="row mx-0 pt-0">
      <div className="col-12 px-0">
        <div className="row mx-0">
          <div className="col-12 px-0">
            <div className="d-flex gap-2 align-items-center justify-content-center text-center">
              <div className="assessment-timer">
                <p className="px-3 py-1 mb-0 rounded-2">Time Remaining:</p>
              </div>
              <div className="assessment-timer">
                <p className="px-3 py-1 mb-0 rounded-2">
                  {time.hours.toString().padStart(2, "0")}:
                  {time.minutes.toString().padStart(2, "0")}:
                  {time.seconds.toString().padStart(2, "0")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentTimer;
