import {
  PAUSE_TIMER,
  START_TIMER,
  STOP_TIMER,
  UPDATE_TIME,
} from "../constants";

export const startTimer = (minutes) => ({
  type: START_TIMER,
  payload: minutes,
});

export const pauseTimer = () => ({
  type: PAUSE_TIMER,
});

export const updateTime = (time) => ({
  type: UPDATE_TIME,
  payload: time,
});

export const stopTimer = () => ({
  type: STOP_TIMER,
});
