import QuizTestCard from "../../../ui/QuizTestCard.jsx";
import { useEffect, useState } from "react";
import { getQuizTestsList } from "../../../../actions/operations.js";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Loader from "../../../../../../shared_components/Loader.jsx";

const QuizTestsList = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { quizTestsList } = useSelector((state) => state.quizTest);
  const { quizTestsListLoading } = useSelector((state) => state.quizTest);
  const [verifiedTestTitles, setVerifiedTestTitles] = useState([]);
  const testUser = JSON.parse(localStorage.getItem("userDetails"));
  const reports = testUser?.data.quizReports || [];

  useEffect(() => {
    dispatch(getQuizTestsList());
  }, [id]);

  return (
    <div className='col-lg-12 p-lg-4 mt-0 pt-0 quiz-list-body'>
      {quizTestsListLoading ? (
        <Loader />
      ) : (
        <>
          <div className='pt-3'>
            <Link
              to='/quiz'
              className='text-decoration-none text-primary d-flex px-5 align-items-center'
            >
              <i className='bi bi-arrow-left me-2'></i>
              <span className='fw-semibold'>Back</span>
            </Link>
          </div>
          <div className='row mx-0 d-flex justify-content-center p-lg-3 px-0 text-left'>
            {/* Toggle for Available Tests */}

            <div className='col-10 py-3 text-left'>
              <div className='d-flex'>
                <span
                  className='toggle-button px-2'
                  data-bs-toggle='collapse'
                  data-bs-target='#availableTests'
                  aria-expanded='true' // Initially expanded
                  aria-controls='availableTests'
                >
                  <i className='bi bi-chevron-compact-down'></i>
                </span>
                <span className='mx-2'>
                  <i className='bi bi-calendar-check'></i>
                </span>
                <h5>Available Tests</h5>
              </div>
            </div>

            {/* Available Tests Collapse Section */}
            <div className='collapse show' id='availableTests'>
              {" "}
              {/* Open by default */}
              {quizTestsListLoading ? (
                <Loader />
              ) : (
                quizTestsList &&
                quizTestsList
                  .filter(
                    (item) => item.quizCategory.toLowerCase() === "quiztest",
                  )
                  .filter(
                    (item) =>
                      !reports.some((report) => report.quizTest === item._id),
                  )
                  .map((item, index) => (
                    <div
                      className='row mx-0 d-flex justify-content-center p-3 text-left'
                      key={index}
                    >
                      <QuizTestCard item={item} index={index} />
                    </div>
                  ))
              )}
            </div>
          </div>
          <div className='row mx-0 d-flex justify-content-center p-lg-3 px-0 text-left'>
            <div className='col-10 py-3 text-left'>
              <div className='d-flex'>
                <span
                  className='toggle-button px-2'
                  data-bs-toggle='collapse'
                  data-bs-target='#previoustest'
                  aria-expanded='false'
                  aria-controls='previoustest'
                >
                  <i className='bi bi-chevron-compact-down'></i>
                </span>
                <span className='mx-2'>
                  <i className='bi bi-calendar-check'></i>
                </span>
                <h5>Previously Taken Tests</h5>
              </div>
            </div>

            {/* Previously Taken Tests Collapse Section */}
            <div className='collapse' id='previoustest'>
              {quizTestsList &&
                quizTestsList
                  .filter(
                    (item) => item.quizCategory.toLowerCase() === "quiztest",
                  )
                  .filter((item) =>
                    reports.some((report) => report.quizTest === item._id),
                  )
                  .map((item, index) => {
                    // Find the report that corresponds to the current quizTest
                    const report = reports.find(
                      (report) => report.quizTest === item._id,
                    );

                    return (
                      <div
                        className='row mx-0 d-flex justify-content-center p-3 text-left'
                        key={index}
                      >
                        <QuizTestCard
                          item={item}
                          index={index}
                          disable={true}
                          certificateUrl={report ? report.certificateUrl : null}
                          prev={true}
                          ispassed={report ? report.isPassed : null}
                        />
                      </div>
                    );
                  })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default QuizTestsList;
