import { Modal, Spin } from "antd";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useEffect, useRef, useState } from "react";
import { PiCertificateBold } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { getCandidateReviewReport } from "../../../quiz_board/actions";

const AssessmentCardVerified = ({ quizTestsList }) => {
  const [verifiedTests, setVerifiedTests] = useState([]);
  const [loader, setLoader] = useState(false);
  const [certificateModal, setCertificateModal] = useState(false);
  const certificateRef = useRef(null);
  const dispatch = useDispatch();
  const testUser = JSON.parse(localStorage.getItem("userDetails"));
  const reports = testUser?.data.quizReports;
  const { candidateReportReviewDetails } = useSelector(
    (state) => state.quizTest,
  );

  const downloadCertificate = async () => {
    const certificateElement = certificateRef.current;

    if (certificateElement) {
      const canvas = await html2canvas(certificateElement, {
        scale: 2,
        useCORS: true,
      });

      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("landscape", "mm", "a4");

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      const imgWidth =
        canvas.width > canvas.height
          ? pdfWidth
          : (pdfWidth / canvas.height) * canvas.width;
      const imgHeight =
        canvas.width > canvas.height
          ? (pdfHeight / canvas.width) * canvas.height
          : pdfHeight;

      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);

      pdf.save("certificate.pdf");
    }
  };

  const openCertificateModal = (item) => {
    setLoader(true);
    setCertificateModal(true);
    dispatch(getCandidateReviewReport(item)).then((res) => {
      setLoader(false);
    });
  };
  console.log(candidateReportReviewDetails);

  useEffect(() => {
    if (quizTestsList && reports) {
      // Find verified tests by matching quizTest._id in reports with quizTestsList._id
      const verifiedTests = reports.reduce((verified, report) => {
        const verifiedTest = quizTestsList.find(
          (test) =>
            test._id === report.quizTest && test.quizCategory === "assessment",
        );

        if (verifiedTest) {
          verified.push({
            title: verifiedTest.quizTitle,
            isPassed: report.isPassed,
            reportId: report._id,
          });
        }
        return verified;
      }, []);

      // Only update state if verifiedTests has changed
      setVerifiedTests((prevVerifiedTests) => {
        const isSame =
          prevVerifiedTests.length === verifiedTests.length &&
          prevVerifiedTests.every(
            (test, index) =>
              test.title === verifiedTests[index].title &&
              test.isPassed === verifiedTests[index].isPassed,
          );

        if (!isSame) {
          return verifiedTests;
        }

        return prevVerifiedTests; // Avoid unnecessary re-render
      });
    }
  }, [quizTestsList, reports]);

  return (
    <>
      <div className='row mx-0'>
        <div className='col-12 px-0'>
          <div className='row mx-0'>
            <h6 className='ms-lg-2 fw-bolder fs-5'>Previously Taken Tests</h6>
            <div className='col-lg-12 col-12 d-lg-flex flex-lg-wrap'>
              {verifiedTests.length > 0 ? (
                verifiedTests.map((item, index) => (
                  <div key={index} className='Assessment-card-manage'>
                    <div className='card rounded m-lg-2 mb-2'>
                      <div className='card-body'>
                        <div className='d-flex justify-content-between align-items-center'>
                          <p className='py-0 fw-bold'>{item.title}</p>
                          <div>
                            <button
                              className={`btn fs-6 ${
                                item.isPassed
                                  ? "btn-outline-success"
                                  : "btn-outline-danger"
                              }`}
                              disabled
                            >
                              {item.isPassed ? "Passed" : "Failed"}
                            </button>
                            <span
                              className='btn btn-outline-warning text-dark ms-2'
                              onClick={() => {
                                openCertificateModal(item.reportId);
                              }}
                            >
                              <PiCertificateBold fontSize={25} />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className='col-12 p-2 text-center'>
                  <p>You are not verified in any test!</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {certificateModal && (
        <Modal
          width={850}
          open={certificateModal}
          title='Certificate'
          onCancel={() => setCertificateModal(false)}
          footer={[
            <button
              key='download'
              className='btn btn-success mx-2'
              onClick={downloadCertificate}
            >
              Download Certificate
            </button>,
            <button
              key='close'
              className='btn btn-primary'
              onClick={() => setCertificateModal(false)}
            >
              Close
            </button>,
          ]}
        >
          <div className='certificate-container' ref={certificateRef}>
            <h1>Certificate of Achievement</h1>
            <p>This is to certify that</p>
            <h2>
              {loader ? (
                <Spin></Spin>
              ) : (
                candidateReportReviewDetails?.user.firstName +
                  " " +
                  candidateReportReviewDetails?.user.lastName || "Student Name"
              )}
            </h2>
            <p>has successfully completed the test</p>
            <h3>
              {loader ? (
                <Spin></Spin>
              ) : (
                candidateReportReviewDetails?.quizTest.quizTitle || "Test Title"
              )}
            </h3>
            <p>
              with{" "}
              {candidateReportReviewDetails?.isPassed === true
                ? "distinction"
                : "an attempt"}{" "}
              on {new Date().toLocaleDateString()}.
            </p>
            <h4
              className={
                candidateReportReviewDetails?.isPassed === true
                  ? "pass"
                  : "fail"
              }
            >
              {loader ? (
                <Spin></Spin>
              ) : candidateReportReviewDetails?.isPassed === true ? (
                "PASS"
              ) : (
                "FAIL"
              )}
            </h4>
            <p className='signature'>Authorized Signature</p>
            <hr />
            <p className='company-name'>
              Powered by <strong>DataCode.in</strong>
            </p>
          </div>
        </Modal>
      )}
    </>
  );
};

export default AssessmentCardVerified;
